import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './Success.module.css';

export function SuccessPage() {
  const [sessionDetails, setSessionDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');
    
     console.log("UseEffect in SuccessPage with sessionId: ", sessionId);

    if (sessionId) {
      axios.get(`https://bangbot.ai/api/payment/session/${sessionId}`)
        .then(response => {
          setSessionDetails(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching session details:', error);
          setError('Failed to fetch session details');
          setLoading(false);
        });
    } else {
      setError('No session ID found in URL');
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <div className={styles['success-container']}>
        <div className={styles.loader}></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`${styles['success-container']} ${styles.error}`}>
        {error}
      </div>
    );
  }

  function TransactionData(){

    if (sessionDetails && sessionDetails.metadata){
      if (sessionDetails.metadata.purchaseType == 'Credits')
      {
        return (
            <div><strong className={styles.strong}>You purchased the{sessionDetails.metadata.tierType} credits package</strong></div>
        );
      } else
      if (sessionDetails.metadata.purchaseType == 'Subscription')
      {
        return (
          <div><strong className={styles.strong}>You have subscribed to the{sessionDetails.metadata.tierType} package</strong></div>
        );
      } else
      {
        return (
          <div><strong className={styles.strong}>Purchase type unrecognized: {sessionDetails.metadata.purchaseType} </strong></div>
        );
      } 

    } else {
      return (
        <div><strong className={styles.strong}>Transaction Details Not Available: {sessionDetails.metadata.purchaseType}</strong></div>
      )
    }
  }

  return (
    <div className={styles['success-container']}>
      <h1 className={styles.h1}>Thank You for Your Purchase!</h1>
      <p className={styles.p}>Your transaction was successful. Here are the details of your purchase:</p>
      <div className={styles.details}>
        <div><strong className={styles.strong}>Your Purchase:</strong> {sessionDetails.metadata.tierType}</div>
        <div><strong className={styles.strong}>Transaction ID:</strong> {sessionDetails.id}</div>
        <div><strong className={styles.strong}>Amount Paid:</strong> ${(sessionDetails.amount_total / 100).toFixed(2)}</div>
        <div><strong className={styles.strong}>Email:</strong> {sessionDetails.customer_details?.email || 'N/A'}</div>
      </div>
      <button className={styles.button} onClick={() => window.print()}>Print Receipt</button>
      <button className={styles.button} onClick={() => window.location.href = '/dashboard'}>Dashboard</button>
    </div>
  );
}


