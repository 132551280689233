import React from 'react';
import image1 from '../assets/image1.png';
import image2 from '../assets/image2.png';
import image3 from '../assets/image3.png';
import image4 from '../assets/image4.png';

import './MakeCommandDocumentation.css';


export function MakeCommandDocumentation() {
    return (
        <div>
          <div className="title" style={{ textAlign: 'center' }}>
            <h1>How to Use the `/make` Command with Our Discord Bot</h1>
          </div>
          <div className="container">
            <h2>Step 1: Choose the Right Image Generation Channel</h2>
            <p>First, you'll need to navigate to one of the following image generation channels on our Discord server:</p>
            <div className="tip-box">
              <p>🪄・image-gen-1<br />🪄・image-gen-2<br />🪄・image-gen-3<br />🪄・image-gen-4<br />🪄・image-gen-5<br />🪄・image-gen-6<br />🪄・horror-image-gen - <b style={{ color: '#FE78FD' }}>this one is for Gore and Horror</b></p>
            </div>
            <br /><br />
            <div>
              <h2>Step 2: Enter Your Command</h2>
              <p>Once you've selected the channel that fits your desired theme, you can create your image generation by using the <code><b style={{ color: '#FE78FD' }}>/make</b></code> command</p>
            </div>
            <div className="tip-box">
              <img src={image1} alt="" /> <b style={{ color: '#FE78FD' }}><br />followed by your prompt.</b>
              <br /><p><br /><img src={image1} alt="" /></p>
            </div>
            <br /><br />
            <div>
              <h3>Image Weight Commands</h3>
              <p>While crafting your image prompt, you can adjust the strength of specific words or phrases:</p>
            </div>
            <div className="tip-box">
              <p>(this) - Gives the word/phrase a 25% boost in strength.</p>
              <p>((this)) - Gives the word/phrase a 50% boost in strength.</p>
              <p>(this:0.6) - Adds 60% strength to the word/phrase.</p>
              <p>(this:1.5) - Adds 150% strength to the word/phrase.</p>
              <br />
              <p><b style={{ color: '#FE78FD' }}>Example:</b> /make a <b style={{ color: '#FE78FD' }}>(dog)</b> playing on the beach during a sunset  <b style={{ color: '#fff' }}>[Gives "dog" a 25% boost in strength]</b></p>
              <p><b style={{ color: '#FE78FD' }}>Example:</b> /make a dog playing on the beach during <b style={{ color: '#FE78FD' }}>((sunset))</b>   <b style={{ color: '#fff' }}>[Gives "sunset" a 50% boost in strength]</b></p>
              <p><b style={{ color: '#FE78FD' }}>Example:</b> /make a dog playing on the <b style={{ color: '#FE78FD' }}>(beach:0.6)</b> during a sunset <b style={{ color: '#fff' }}>[Adds 60% strength to "beach"]</b></p>
              <p><b style={{ color: '#FE78FD' }}>Example:</b> /make <b style={{ color: '#FE78FD' }}>(dog:1.5)</b> playing on the beach during a sunset <b style={{ color: '#fff' }}>[Adds 150% strength to "dog."]</b></p>
            </div>
            <br />
            <div>
              <h3>Image Generation Flags</h3>
              <p>For further customization, you can use the following flags:</p>
            </div>
            <div className="tip-box">
              <p>-wide  - Specifies an aspect ratio of 16:9.</p>
              <p>-tall  - Specifies an aspect ratio of 9:16.</p>
              <p>-34    - Specifies an aspect ratio of 3:4.</p>
              <p>-43    - Specifies an aspect ratio of 4:3.</p>
              <p>-tile  - Creates an image in tile format, great for wallpapers and backgrounds.</p>
              <br />
              <p><b style={{ color: '#FE78FD' }}>Example:</b> /make a dog playing on the beach during a sunset -wide <b style={{ color: '#fff' }}>[16:9 aspect ratio]</b> </p>
              <p><b style={{ color: '#FE78FD' }}>Example:</b> /make a dog playing on the beach during a sunset -tall <b style={{ color: '#fff' }}>[9:16 aspect ratio]</b></p>
              <p><b style={{ color: '#FE78FD' }}>Example:</b> /make a dog playing on the beach during a sunset -34   <b style={{ color: '#fff' }}> [3:4 aspect ratio]</b></p>
              <p><b style={{ color: '#FE78FD' }}>Example:</b> /make a dog playing on the beach during a sunset -43   <b style={{ color: '#fff' }}> [4:3 aspect ratio]</b></p>
              <p><b style={{ color: '#FE78FD' }}>Example:</b> /make a dog playing on the beach during a sunset -tile <b style={{ color: '#fff' }}>  [tiled boxed image]</b></p>
            </div>
            <br /><br />
            <div>
              <h2>Step 3: Wait for the Response</h2>
              <p>Once you enter your command and hit enter, the bot will return a message that says<br />
              "Your request is in queue! We will notify you when it is ready."<br />
              </p>
            </div>
            <div className="tip-box"><br />
              <div style={{ textAlign: 'center' }}><img src={image3} alt="" /></div>
            </div>
            <br /><br />
            <div>
              <h2>Step 4: Receive Your Generated Image</h2>
              <p>Your prompt will go into the queue in the order it was received. When the bot finishes creating your text-to-image, it will post it in the room you ran the command and ping you with a notification letting you know it's ready.</p>
              <div className="tip-box"><br /><img src={image4} alt="" /></div>
            </div>
            <br /><br />
            <div>
              <h3>Emoji-Interaction Commands</h3>
              <p>In FullJourney, you can interact with the generated image grid using emoji buttons under each image:</p>
              <div className="tip-box"><br />
                <p>💖 - Love an image (click again to remove love)</p>
                <p>✏️ - Edit the prompt and regenerate the image</p>
                <p>🔄 - Re-roll the generated image</p>
                <p>❌ - Delete the generated image</p>
              </div>
            </div>
          </div>
        </div>
      );
};