import React, { useState, useEffect } from 'react';
import { GetVersion, getUserDetails, logoutUser, updateSubscriptionStatus } from '../../Utils/api.js';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './index.css';


export function DashboardPage() {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [update, setUpdate] = useState(0);
    const [subscriptionStatusDisplay, setSubscriptionStatusDisplay] = useState('Fetching subscription status...');
    const [creditsDisplayed,setcreditsDisplayed] = useState('Fetching credits...');

    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const processCreditsDisplay = (credits) => {
        if (credits > 14999)
            return "Unlimited";
        else
            return credits;
    };

    useEffect(() => {
    console.log("Version is: ",GetVersion());

        if (user) {
            const fetchSubscriptionStatus = async () => {
                try {
                    let response = await updateSubscriptionStatus(user.user_id);
                    let updatedUser = response.data;
                    let displayValue;

                    setSubscriptionStatusDisplay(updatedUser.subscription_status);
                    setcreditsDisplayed(processCreditsDisplay(updatedUser.credits));
                } catch (err) {
                    console.log(err);
                }
            };
            fetchSubscriptionStatus();
        }
    }, [user, update]);

    useEffect(() => {
        if (query.get('refresh') === 'true') {
            setUpdate(update + 1);
        }
    }, [query.get('refresh')]);

    useEffect(() => {       
            getUserDetails().then(({data}) => {
                setUser(data);
                setLoading(false);
            }).catch((err) => {
                navigate(`/menu?${process.env.REACT_APP_CURRENT_VERSION}`);
                setLoading(false);
            });        
    }, [navigate, update]);

    const logoutButton = () => {
        logoutUser().then(() => {
            navigate(`/menu?${process.env.REACT_APP_CURRENT_VERSION}`); 
        }).catch((err) => {
            console.log(err);
        });
    };

    const navigateButton = (path) => {
        navigate(path);        
    };

    // first check if the user data is loaded and exists
    if (!loading && user)
    {
        return (
            <div className="dashboard-page p-8">
              <div className="flex flex-col">
                {user.subscription_status !== 'free' 
                  ? <button id="testsubscriptionButton" className="bg-blue-500 text-white p-2 m-2 rounded"   style={{borderRadius: "12px"}} onClick={() => navigateButton(`/subscriptionstatus?${process.env.REACT_APP_CURRENT_VERSION}`)}>Subscription Status</button>
                  : <button id="testsubscriptionButton" className="bg-blue-500 text-white p-2 m-2 rounded subscribe-button-text"   style={{borderRadius: "12px"}} onClick={() => navigateButton(`/subscription?${process.env.REACT_APP_CURRENT_VERSION}`)}>Click To Subscribe</button>
                }
              </div>
              <button id="testcreditsButton" className="bg-blue-500 text-white p-2 m-2 rounded"   style={{borderRadius: "12px"}} onClick={() => navigateButton(`/credits?${process.env.REACT_APP_CURRENT_VERSION}`)}>Buy Credits</button>
          
              <div className="flex flex-col">
                <div className="flex" style={{paddingBottom: '20px'}}>
                  <span style={{width: '200px'}} className="text-lg font-semibold">Discord Name:</span>
                  <span className="text-lg font-semibold">{user.discordname}</span>
                </div>
                <div className="flex" style={{paddingBottom: '20px'}}>
                  <span style={{width: '200px'}} className="text-lg font-semibold">Credits:</span>
                  <span className="text-lg font-semibold">🪙{creditsDisplayed}</span>
                </div>
                <div className="flex" style={{paddingBottom: '20px'}}>
                  <span style={{width: '200px'}} className="text-lg font-semibold">Subscription Status:</span>
                  <span className="text-lg font-semibold">{subscriptionStatusDisplay}</span>
                </div>
              </div>         
              <button id="logoutButton" className="bg-blue-500 text-white p-2 m-2 rounded"   style={{borderRadius: "12px"}} onClick={logoutButton}>Logout</button>
            </div>
          );       
              } else {
        return (
        <div className="fullscreen-spinner">
                <div className="spinner" id="spinner"></div>
              </div>);
    }
}


