import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Adding an entry to the backend
export const addEntryAsync = createAsyncThunk(
  'changelog/addEntryAsync',
  async (entryContent) => {
    const response = await axios.post('https://www.bangbot.ai/api/changelog', { content: entryContent });
    return response.data;
  }
);

// Update an entry in the backend
export const updateEntryAsync = createAsyncThunk(
  'changelog/updateEntryAsync',
  async (entryUpdate) => {
    const { id, ...content } = entryUpdate;
    console.log("Here inside of updateEntryAsync with id: ", id," content: ", content)
    const response = await axios.put(`https://www.bangbot.ai/api/changelog/${id}`, { content });
    return response.data;
  }
);

// Delete an entry from the backend
export const deleteEntryAsync = createAsyncThunk(
  'changelog/deleteEntryAsync',
  async (id) => {
    const response = await axios.delete(`https://www.bangbot.ai/api/changelog/${id}`);
    return { id };
  }
);

// Grabs/fetch's all entries from the backend
export const fetchChangelogEntries = createAsyncThunk(
  'changelog/fetchChangelogEntries',
  async () => {
    const response = await axios.get('https://www.bangbot.ai/api/changelog');
    return response.data;
  }
);

// Grabs/fetch's the number of entries from the backend
export const fetchChangelogEntryCount = createAsyncThunk(
  'changelog/fetchChangelogEntryCount',
  async () => {
    const response = await axios.get('https://www.bangbot.ai/api/changelog/entrycount');
    return response.data.count;
  }
);

const changelogSlice = createSlice({
  name: 'changelog',
  initialState: {
    entries: [],
    entryCount: 0,
    status: 'idle',
    error: null
  },
  reducers: {
    addEntry: (state, action) => {
      state.entries.push(action.payload);
    },
    removeEntry: (state, action) => {
      state.entries = state.entries.filter(entry => entry.id !== action.payload);
    },
    updateEntry: (state, action) => {
      const index = state.entries.findIndex(entry => entry.id === action.payload.id);
      if (index !== -1) {
        state.entries[index] = action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addEntryAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchChangelogEntries.fulfilled, (state, action) => {
        state.entries = action.payload;
      })
      .addCase(addEntryAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.entries.push(action.payload);
      })
      .addCase(addEntryAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateEntryAsync.fulfilled, (state, action) => {
        const index = state.entries.findIndex(entry => entry.id === action.payload.id);
        if (index !== -1) {
          state.entries[index] = action.payload;
        }
      })
      .addCase(updateEntryAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteEntryAsync.fulfilled, (state, action) => {
        state.entries = state.entries.filter(entry => entry.id !== action.payload.id);
      })
      .addCase(deleteEntryAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchChangelogEntryCount.fulfilled, (state, action) => {
        state.entryCount = action.payload;
      });
  }
});

export const { addEntry, removeEntry, updateEntry } = changelogSlice.actions;
export default changelogSlice.reducer;
