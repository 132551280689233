import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateEntryAsync } from '../../Component/Redux/changelogSlice.js';
import styles from './editChangelogEntryPage.module.css'; 
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Updated toolbar options
const modules = {
  toolbar: {
    container: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['link', 'image'],  // Link and image
      ['clean']
    ],
    handlers: {
      image: imageHandler,
    }
  },
};

function imageHandler() {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async () => {
    const file = input.files[0];
    const formData = new FormData();

    formData.append('image', file);

    // Upload image to your server or a 3rd-party service
    const imageUrl = await uploadImageToServer(formData);

    console.log("Image URL: '", imageUrl, "'");

    // Insert uploaded image URL into editor
    const range = this.quill.getSelection(true);
    this.quill.insertEmbed(range.index, 'image', imageUrl);

    // Restore cursor to the right place
    this.quill.setSelection(range.index + 1);
  }
}

export function EditChangelogEntryPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const entry = useSelector(state => state.changelog.entries.find(e => e.id === parseInt(id)));

  const [changeTitle, setChangeTitle] = useState(entry ? entry.changeTitle : "");
  const [editorContent, setEditorContent] = useState(entry ? entry.changeText : "");

  useEffect(() => {
    if (entry) {
      setChangeTitle(entry.changeTitle || "");
      setEditorContent(entry.changeText || "");
    } else {
      setChangeTitle("");
      setEditorContent("");
    }
  }, [entry]);

  const handleSave = () => {
    dispatch(updateEntryAsync({ id: parseInt(id), changeTitle, changeText: editorContent }))
      .then(() => {
        navigate(`/changelog?${process.env.REACT_APP_CURRENT_VERSION}`);
      })
      .catch((error) => {
        console.error("Error updating entry:", error);
      });
  }

  return (
    <div className={styles.container}>
        <h2 className={styles.title}>Edit Changelog Entry</h2>
        <div className={styles.inputGroup}>
            <label>Title:</label>
            <input className={styles.input} value={changeTitle} onChange={e => setChangeTitle(e.target.value)} />
        </div>
        <div className={styles.inputGroup}>
            <label>Text:</label>
            <ReactQuill className={styles.imgStyle} value={editorContent} onChange={setEditorContent} modules={modules} />
        </div>
        <button className={styles.button} onClick={handleSave}>Save</button>
    </div>
  );
}

async function uploadImageToServer(formData) {
  const API_ENDPOINT = 'https://www.bangbot.ai/api/changelog/upload';  // Replace with your server's endpoint

  try {
      const response = await axios.post(API_ENDPOINT, formData);
      return response.data.url;
  } catch (error) {
      console.error("Error uploading the image:", error);
      throw error;
  }
}