import React from 'react';
import './ErrorComponent.css';

const ErrorComponent = () => {
    return (
        <div className="error-page">
            <div className="error-message">
                <p>Sorry, this page is currently unavailable but the FullJourney.ai team is working hard to bring it back up for you.</p>
                <p>We appreciate your patience.</p>
            </div>
        </div>
    );
};

export default ErrorComponent;