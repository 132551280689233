// ChangelogDetailPage.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { removeEntry, updateEntryAsync, deleteEntryAsync } from '../../Component/Redux/changelogSlice.js';
import styles from './changelogDetailPage.module.css';




export function ChangelogDetailPage() {
  const { title } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const decodedTitle = decodeURIComponent(title);
  const entries = useSelector(state => state.changelog.entries);
  const entry = entries.find(e => e.changeTitle === decodedTitle);

  const handleDelete = () => {
    dispatch(deleteEntryAsync(entry.id));
    navigate(`/changelog?${process.env.REACT_APP_CURRENT_VERSION}`)
};

if (!entry) return <div>Entry not found</div>;

  return (
    <div className={styles.container}>
        <Helmet>
            <title>{`Changelog: ${entry.changeTitle}`}</title>
            <meta name="description" content={`Details about changelog entry: ${entry.changeTitle}`} />
        </Helmet>

        <h2 className={styles.title}>{entry.changeTitle}</h2>
        <p className={styles.date}>{entry.changeDate}</p>
        <p className={styles["entry-text"]} dangerouslySetInnerHTML={{ __html: entry.changeText }}></p>

        <div className={styles.buttonGroup}>
            <button onClick={() => navigate(`/changelog/edit/${entry.id}`)}>Edit</button>
            <button onClick={handleDelete}>Delete</button>
        </div>
    </div>
);
}
