import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { addEntryAsync } from '../../Component/Redux/changelogSlice.js';
import axios from 'axios'; // You need this for the image uploading
import ReactQuill from 'react-quill';
import { getUserAuthLevel, getAuthorizationLevel } from '../../Utils/api.js';

import 'react-quill/dist/quill.snow.css'; // Import styles (consider using your styles)
import styles from './editChangelogEntryPage.module.css';

// Updated toolbar options
const modules = {
  toolbar: {
    container: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['link', 'image'],  // Link and image
      ['clean']
    ],
    handlers: {
      image: imageHandler,
    }
  },
};

function imageHandler() {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async () => {
    const file = input.files[0];
    const formData = new FormData();

    formData.append('image', file);

    // Upload image to your server or a 3rd-party service
    const imageUrl = await uploadImageToServer(formData);

    console.log("Image URL: '", imageUrl, "'");

    // Insert uploaded image URL into editor
    const range = this.quill.getSelection(true);
    this.quill.insertEmbed(range.index, 'image', imageUrl);

    // Restore cursor to the right place
    this.quill.setSelection(range.index + 1);
  }
}

export function AddChangelogEntryPage() {
    const [changeTitle, setChangeTitle] = useState('');
    const [editorContent, setEditorContent] = useState('');
    const dispatch = useDispatch();
    const { version } = useParams();
    const navigate = useNavigate();
    const [authorizationLevel, setAuthorizationLevel] = React.useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addEntryAsync({ changeTitle, changeText: editorContent }));
        setChangeTitle('');
        setEditorContent('');
        navigate(`/changelog?${process.env.REACT_APP_CURRENT_VERSION}`);
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Add New Changelog Entry</h2>
            <form onSubmit={handleSubmit}>
                <div className={styles.inputGroup}>
                    <label>Title:</label>
                    <input
                        className={styles.input}
                        type="text"
                        value={changeTitle}
                        onChange={(e) => setChangeTitle(e.target.value)}
                    />
                </div>
                <div className={styles.inputGroup}>
                    <label>Text:</label>
                    <ReactQuill
                        value={editorContent}
                        onChange={setEditorContent}
                        modules={modules}
                    />
                </div>
                <button className={styles.button} type="submit">Add Entry</button>
            </form>
        </div>
    );
}

async function uploadImageToServer(formData) {
  const API_ENDPOINT = 'https://www.bangbot.ai/api/changelog/upload';  // Replace with your server's endpoint

  try {
      const response = await axios.post(API_ENDPOINT, formData);
      return response.data.url;
  } catch (error) {
      console.error("Error uploading the image:", error);
      throw error;
  }
}
