import React, { useState, useEffect, useRef } from 'react';
import { GetVersion, updateSubscriptionStatus, cancelSubscription, getSubscriptionDetails, getUserDetails, createStripeCustomer, renewSubscription } from '../../Utils/api.js';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Modal from '../../Component/OkModal'; 
import './index.css';

export function SubscriptionStatusPage() {
    const [subscription, setSubscription] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [user, setUser] = useState(null);
    const [update, setUpdate] = useState(0);
  
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const navigated = useRef(false);

    useEffect(() => {
        console.log("Version is: ",GetVersion());

        const fetchUserAndSubscriptionDetails = async () => {
            try {
                //console.log("Fetching user details in SubscriptionStatusPage...");
                const { data } = await getUserDetails();
                //console.log("Got updated user:  ",data);
                setUser(data);
                setLoading(false);

                if (data.stripeid) {
                    const { data: subscriptionData } = await getSubscriptionDetails(data.stripeid);
                    if (subscriptionData) {
                        //console.log("SubscriptionStatusPage1: Got subscription details:  ",subscriptionData);

                        setSubscription(subscriptionData);
                    } else {
                        setSubscription(null);
                    }
                }
            } catch (err) {
                navigate(`/menu?${process.env.REACT_APP_CURRENT_VERSION}`);
                setLoading(false);
            }
        };

        fetchUserAndSubscriptionDetails();
    }, [navigate, update]);

    useEffect(() => {
        if (query.get('refresh') === 'true' && !navigated.current) {
            setUpdate(update + 1);
            navigate(`/subscriptionstatus?${process.env.REACT_APP_CURRENT_VERSION}`); // Reset the query after use
            navigated.current = true;
        } else if (query.get('refresh') !== 'true') {
            navigated.current = false;
        }
    }, [query.get('refresh')]);

    const handleCancelSubscription = async () => {
        setShowModal(true);
    };

    const renewSubscriptionButton = async (subscription) => {
        setLoading(true);
        try {
            await renewSubscription(subscription.subscription_id);
            await updateSubscriptionStatus(user.user_id);
        } catch (err) {
            console.error("Error renewing subscription:", err);
        }
        setLoading(false);
        navigate('/subscriptionstatus?refresh=true');
    };

    const confirmCancelSubscription = async () => {
        setLoading(true);

        try {
        await cancelSubscription(subscription.subscription_id);
        await updateSubscriptionStatus(user.user_id);
        } catch (err) {
            console.error("Error cancelling subscription:", err);
        }
        
        setShowModal(false);
        navigate('/subscriptionstatus?refresh=true');

        setLoading(false);
    };

    const changePaymentMethod = async () => {
        setLoading(true);
        try {
        } catch (err) {
            console.error("Error changing payment method:", err);
        }
        setLoading(false);
    };

    /*
    This function calls the following express code route
    router.post('/create-portal-session', async (req, res) => {
    // grabe the customer_id from the request body
    const { customer_id } = req.body;
    
    const session = await stripe.billingPortal.sessions.create({
        customer: customer_id,
        return_url: 'https://www.bangbot.ai/dashboard',
    });

    res.redirect(session.url);
    });
    */
    const createNewPortalSession = async () => {
        setLoading(true);
        try {
            const response = await axios.post("https://www.bangbot.ai/api/payment/create-portal-session", { customer_id: user.stripeid });
            window.location.href = response.data.url; // Redirect the user
        } catch (err) {
            console.error("Error creating portal session:", err);
            // Handle errors here, such as displaying a message to the user
        }
        setLoading(false);
    };



    const displaySubscriptionStatus = (user) => {
        if (!subscription) return 'Fetching subscription status...';
        if (subscription.subscription_status === 'free') return 'Free';
        if (subscription.cancel_at_period_end == false) return `Active, renews on ${new Date(subscription.current_period_end * 1000).toLocaleDateString()} for a charge of: ${subscription.upcomingAmount / 100.0}`;
        if (subscription.cancel_at_period_end == true) {
            if (subscription.downgrade_to_subname)
                return `Currently Active but downgrades on ${new Date(subscription.current_period_end * 1000).toLocaleDateString()} for a charge of: ${subscription.upcomingAmount / 100.0} to ${subscription.downgrade_to_subname}`;
            else
                return `Currently Active but cancels on ${new Date(subscription.current_period_end * 1000).toLocaleDateString()} for a charge of: ${subscription.upcomingAmount / 100.0}`;
        }
        return subscription.subscription_status + " current period end: " + new Date(subscription.current_period_end * 1000).toLocaleDateString();
    };

    return user && (
        <div className="subscription-status-page">
            <h1>Your subscription</h1>
            {subscription ? (
                <div className={`tier-card ${subscription.subscription_status}`} style={{backgroundColor: 0x00ff15}}>
                    <h3>{subscription.subscription_status}</h3>
                    <p>Price: {subscription.discounted ? <span style={{ color: 'green' }}>{subscription.discountName} </span> : ''}${subscription.actualPrice / 100.0}/ Month</p>
                </div>
            ) : (
                <div className="fullscreen-spinner">
                    <div className="spinner" id="spinner"></div>
                </div>
            )}

            <h2>{subscription ? subscription.subscription_status : <div className="fullscreen-spinner"><div className="spinner" id="spinner"></div></div>}</h2>
            <p className="text-lg font-semibold">{displaySubscriptionStatus(user)}</p>
            
            {subscription && (user.lastname === '0' ? (
                <button onClick={handleCancelSubscription}>Cancel Subscription</button>
            ) : (
                <button onClick={() => renewSubscriptionButton(subscription)}>Renew Subscription</button>
            ))}

            {loading && (
                <div className="fullscreen-spinner">
                    <div className="spinner" id="spinner"></div>
                </div>
            )}

            <Modal
                title="Confirm"
                visible={showModal}
                onOk={confirmCancelSubscription}
                onCancel={() => setShowModal(false)}
            >
                <p style={{color: 'black'}}>Do you really want to cancel?</p>
            </Modal>

            <div className="go-to-subscription-change">
                <button onClick={() => navigate(`/subscription?${process.env.REACT_APP_CURRENT_VERSION}`)}>Change Subscription</button>
            </div>

            <div className="go-to-change-payment-method">
                <button onClick={() => {createNewPortalSession();}}>Change Default Payment Method</button>
            </div>

            <div className="back-to-dashboard">
                <button onClick={() => navigate(`/dashboard?refresh=true`)}>Dashboard</button>
            </div>
            <p> version is: {GetVersion()}</p>
        </div>
    );
}
