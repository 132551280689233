import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import { GetVersion,getUserDetails,getGoogleAuthUrl } from '../../Utils/api.js';
import ErrorComponent from '../../Component/ErrorComponent.jsx';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {gapi} from 'gapi-script';
//import Counter from '../../Component/Redux/counterRx.js';

import './index.css';
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const GOOGLE_OAUTH2_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH2_CLIENTID;



export function MenuPage() {
    console.log("Version is: ",GetVersion());
    const [hasError, setHasError] = useState(false);
    const navigate = useNavigate();

    // Our Google OAuth2 accessToken
    //var accessToken = gapi.auth.getToken().access_token;


    // This is the user object that will be returned from the Discord OAuth2 login page
    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [googleCode, setGoogleCode] = React.useState('getting code...');


    useEffect(() => {
                const fetchGoogleCode = async () => {
                    try {
                        let response = await getGoogleAuthUrl("thebestpassword");
                        let theCode = response.data;
    
                        console.log("response:",response, " response.data is: ",theCode);
                        setGoogleCode(theCode);
                    } catch (err) {
                        console.log(err);
                    }
                };

                fetchGoogleCode();
        }, []);
    
    useEffect(() => {
        document.title = 'Menu - bangbot.ai Your complete adult content ai Media Generation Platform!';
    }, []);

    const goToDiscord = () => {
        window.location.href = "https://discord.gg/kukNqTv7jA";
    };
    
    const loginViaDiscord = () => {
        // 'localhost:3001/auth' is our Express server's address for the Discord OAuth2 login page
        window.location.href = "http://www.bangbot.ai/api/auth/discord";
    };

       
    
    // This is the code that will run when the component is first rendered
    React.useEffect(() => {
            getUserDetails().then(({data}) => {
                console.log(data);
                setUser(data);
                setLoading(false);
                if (data) {
                    navigate(`/dashboard?${process.env.REACT_APP_CURRENT_VERSION}`); // If the user is logged in, redirect them to the dashboard page
                }
            }).catch((err) => { 
                console.log(err);
                setLoading(false);
                //setHasError(true);
            });
    }, [navigate]);

    if (hasError) {
        return <ErrorComponent />;
      }

    return !loading && (
        <div className="App">
            <header className="App-header">
                Welcome to bangbot
                <button 
                    id="loginButton"
                    className="rounded-button"  
                    style={{fontSize: "20px", marginTop: "20px"}} 
                    onClick={loginViaDiscord}>
                    Login Via Discord
                </button>
                <button
                    id="changelogButton"
                    className="rounded-button"
                    style={{fontSize: "20px", marginTop: "20px"}}
                    onClick={() => navigate(`/changelog?${process.env.REACT_APP_CURRENT_VERSION}`)}>
                    Changelog
                </button>
                
                {/* Use our getGoogleAuthUrl function to display the Google OAuth2 login page 
                  Here is the code for getGoogleAuthUrl express api
                  router.get('/google', (req, res) => {
                    res.send('<a href="/api/auth/google/redirect">Login with Google</a>')
            });*/}
              <div dangerouslySetInnerHTML={{ __html: googleCode }}></div>
            </header>
            <p> version is: {GetVersion()}</p>
        </div>
    );
}
