import React, {useState, useEffect} from 'react';
import './App.css';
import {Routes, Route } from "react-router-dom";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useLocation} from 'react-router-dom';
import { SubscriptionProvider } from './Component/SubscriptionProvider'; 
import { CreditPackageProvider } from './Component/CreditPackageProvider';
import {TermsOfService} from './Component/TermsOfService.jsx';
import {PrivacyPolicy} from './Component/PrivacyPolicy.jsx';
import {Documentation} from './Component/Documentation.jsx';
import {MakeCommandDocumentation} from './Component/MakeCommandDocumentation.jsx';
import {VideoCommandDocumentation} from './Component/VideoCommandDocumentation.jsx';
import {AudioCommandDocumentation} from './Component/AudioCommandDocumentation.jsx';
import {LipSyncDocumentation} from './Component/LipSyncDocumentation.jsx';
import { ChangelogDetailPage } from './Pages/ChangelogPage/changelogDetailPage.js';
import { AddChangelogEntryPage } from './Pages/ChangelogPage/addchangelogEntryPage.js';
import { EditChangelogEntryPage } from './Pages/ChangelogPage/editchangelogEntryPage.js';

// Import the pages that we want to route to
import {SubChangeLandingPage,SubscriptionStatusPage, SuccessPage, DashboardPage, MenuPage, 
        SubscriptionSelectionPage, CheckoutPage, CreditPackageSelectionPage, CommandDashboardPage,
        ChangelogPage } from "./Pages";

// Stripe 
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY_TESTMODE);

function App() {
    const location = useLocation();

    // Initialize the clientSecret with a default value
    const [clientSecret, setClientSecret] = useState('{{DEFAULT_CLIENT_SECRET}}');
    
    const [options, setOptions] = useState({
        clientSecret: clientSecret,
        appearance: {/*...*/},
    });

    useEffect(() => {
        // Check if location.state and location.state.paymentIntent exist before updating
        if(location.state && location.state.paymentIntent) {
            const newClientSecret = location.state.paymentIntent.client_secret || '{{DEFAULT_CLIENT_SECRET}}';
            setClientSecret(newClientSecret);
            console.log("location.state?.clientSecret set to: " + newClientSecret);
            
            setOptions({
                ...options,
                clientSecret: newClientSecret
            });
        }
    }, [location.state?.paymentIntent]);
    
    return (
        <CreditPackageProvider>
            <SubscriptionProvider>
                <Routes>
                    <Route path="/" element={<MenuPage />} />
                    <Route path="/menu" element={<MenuPage />} />
                    <Route path="/success" element={<SuccessPage key={location.key} />} />
                    <Route path="/subchangelanding" element={<SubChangeLandingPage key={location.key} />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
                    <Route path="/dashboard" element={<DashboardPage key={Date.now()} />} />
                    <Route path="/termsofservice" element={<TermsOfService/>} />
                    <Route path="/documentation" element={<Documentation/>} />
                    <Route path="/makecommanddocumentation" element={<MakeCommandDocumentation/>} />
                    <Route path="/videocommanddocumentation" element={<VideoCommandDocumentation/>} />
                    <Route path="/audiocommanddocumentation" element={<AudioCommandDocumentation/>} />
                    <Route path="/LipSyncDocumentation" element={<LipSyncDocumentation/>} />
                    <Route path="/privacypolicy2" element={<CommandDashboardPage/>} />
                    <Route path="/changelog/:title" element={<ChangelogDetailPage/>} />
                    <Route path="/changelog" element={<ChangelogPage/>} />
                    <Route path="/add-changelog-entry" element={<AddChangelogEntryPage />} />
                    <Route path="/changelog/edit/:id" element={<EditChangelogEntryPage />} />
                    
                    <Route path="/pay" element={
                        <Elements stripe={stripePromise}>
                            <SubscriptionSelectionPage />
                        </Elements>} 
                    />

                    <Route path="/subscription" element={
                        <Elements stripe={stripePromise}>
                            <SubscriptionSelectionPage />
                        </Elements>} 
                    />

                    <Route path="/credits" element={
                        <Elements stripe={stripePromise}>
                            <CreditPackageSelectionPage />
                        </Elements>} 
                    />
                    {
                        clientSecret !== '{{DEFAULT_CLIENT_SECRET}}' && 
                        <Route path="/checkout" element={
                            <Elements stripe={stripePromise} options={options}>
                                <CheckoutPage />
                            </Elements>
                        } />
                    }
  
                    <Route path="/subscriptionstatus" element={
                        <Elements stripe={stripePromise}>
                            <SubscriptionStatusPage />
                        </Elements>}
                    />
                </Routes>
            </SubscriptionProvider>
        </CreditPackageProvider>
    );
}

export default App;
