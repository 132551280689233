import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetVersion, getUserDetails } from '../../Utils/api.js';
import CreditPackageContext from '../../Component/CreditPackageContext';
import './index.css';

export function CreditPackageSelectionPage() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const creditPackages = useContext(CreditPackageContext);

  useEffect(() => {
    console.log("Version is: ", GetVersion());

    const fetchUserDetails = async () => {
      try {
        setLoading(true);
        const { data } = await getUserDetails();
        setUser(data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching user details: ", err);
        navigate(`/menu?${process.env.REACT_APP_CURRENT_VERSION}`);
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [navigate]);

  return !loading && (
    <div className="credit-package-selection-page">
      <h1>Choose a credit package</h1>
      <div className="package-container">
        {creditPackages.map((thepackage, index) => (
          <form 
            action="https://bangbot.ai/api/payment/create-checkout-session" 
            method="POST"
            className="package-card"
            key={index}
            style={{ backgroundColor: thepackage.color }}
            onClick={(e) => e.currentTarget.submit()} 
          >
            {Object.entries(thepackage).map(([key, value]) => {
              if (key === 'color') {
                return null;
              }
              if (key === 'name') {
                return <h3 key={key}>{value}</h3>;
              }
              return <p key={key}><span className="attribute-name">{key}:</span> {value}</p>;
            })}
            {/* Hidden inputs for form submission */}
            <input type="hidden" name="email" value={user.email || ''} />
            <input type="hidden" name="userId" value={user.user_id} />
            <input type="hidden" name="stripeId" value={user.stripeid} />
            <input type="hidden" name="purchaseType" value="Credits" />
            <input type="hidden" name="tierType" value={thepackage.name} />
          </form>
        ))}
      </div>
      <p> version is: {GetVersion()}</p>
    </div>
  );
  
}
